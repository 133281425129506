import React, { useState, useEffect } from 'react';
import { Dropdown } from './Dropdown';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const defaultLanguageCode = 'en';
  const history = useHistory();
  const location = useLocation();

  const getLanguageFromPath = (path: string) => {
    const pathParts = path.split('/');
    if (pathParts[1] && pathParts[1].length === 2) {
      return pathParts[1];
    }
    return defaultLanguageCode;
  };

  const [langCode, setLangCode] = useState(
    getLanguageFromPath(location.pathname)
  );

  useEffect(() => {
    const urlLangCode = getLanguageFromPath(location.pathname);
    if (urlLangCode !== langCode) {
      setLangCode(urlLangCode);
    }
    i18n.changeLanguage(langCode);
    localStorage.setItem('language', langCode);
  }, [langCode, i18n, location.pathname]);

  const options = [
    { name: i18n.t('Irish'), value: 'ga' },
    { name: i18n.t('Scottish Gaelic'), value: 'gd' },
    { name: i18n.t('English'), value: 'en' },
  ];

  const changeLanguage = (code: string) => {
    localStorage.setItem('language', code);
    setLangCode(code);

    // update URL with new language code
    const pathParts = location.pathname.split('/');
    if (pathParts[1] && pathParts[1].length === 2) {
      // If URL already has a language code, replace it
      pathParts[1] = code;
    } else {
      // If URL doesn't have a language code, add it after first slash
      pathParts.splice(1, 0, code);
    }
    history.push(pathParts.join('/') + location.search);
  };

  const getLanguageName = (code: string) => {
    const languageOption = options.find(option => option.value === code);
    return languageOption ? languageOption.name : i18n.t('English');
  };

  return (
    <Dropdown
      key={langCode}
      options={options}
      selected={langCode}
      onChange={(e: any) => changeLanguage(e)}
      selectProps={{
        'aria-label': i18n.t('Select language')
      }}
    />
  );
};
