import { useFetch } from '../hooks/use-fetch-data';
import { useAPIBase } from '../hooks/use-api-base';

const base = useAPIBase();

async function getTitle(id: string, key: string, lang: string) {
    const endpoints = {
        'lays': 'laytype',
        'people': 'person',
        'places': 'place',
        'item': 'item',
        'collection': 'collection',
        'institution': 'institution'
    }
    const endpoint = Object(endpoints)[key];
    const url = `${base}/${endpoint}/${id}`;
    const data = await useFetch(url);
    switch(endpoint){
        case 'laytype':
            return Object(data)[`title_${lang}`];
            break;
        case 'person':
            let name = Object(data).canonical_name;
            return name;
            break;
        case 'place':
            let villages = Object(data).town_village;
            return Object(villages)[`town_village_name_${lang}`];
            break;
        case 'item':
            return Object(data).title;
            break;
        case 'collection':
            return Object(data).collection_name;
            break;
        case 'institution':
            return Object(data).institution_name;
            break;
        default:
            return '';
    }

}

export const getPageName = (route: string, userLanguage: string, t: any) => {
    const parts = route.split("/").filter(part => part !== "");
    const langPrefix = parts[0];
    const base = parts[1] || "";
    const key = `/${base}`
    const id = parts[2];

    const mapping = {
        "/": "Home",
        "/lays": "Lays",
        "/people": "People",
        "/archives": "Archives",
        "/resources": "Resources",
        "/about-us": "About Us",
        "/navigating-the-database": "Navigating the Database",
        "/characters": "Characters",
        "/glossary": "Glossary",
        "/creating-the-catalogue": "Creating the Catalogue",
        "/further-information": "Further Information",
        "/contact": "Contact",
        "/places": "Places",
        "/search": "Search",
        "*": "Page Not Found"
    }

    // if root, can return immediately
    if (!base && langPrefix) {
        return Promise.resolve(t("Home"));
    }

    // pageName needs to be translated
    const pageName = Object(mapping)[key];
    if(id){
        return getTitle(id, base, userLanguage).then((title) => {
            return `${t(pageName)} - ${title}`;
        });
    } else {
        return Promise.resolve(t(pageName));
    }
}
