import React, { useState, useEffect } from 'react';
import { TitleBar } from './TitleBar';
import { Home } from '../pages/home';
import { Lays } from '../pages/lays';
import { Places } from '../pages/places';
import { People } from '../pages/people';
import { Archives } from '../pages/archives';
import { Contact } from '../pages/contact';
import { DynamicPage } from '../pages/dynamic-page';
import { LayType } from '../pages/laytype';
import { Item } from '../pages/item';
import { Institution } from '../pages/institution';
import { Person } from '../pages/person';
import { SearchPage } from '../pages/es-searchpage';
import { Collection } from '../pages/collection';
import { NotFound } from '../pages/not-found';
import { NavigationIcon } from '../atoms/NavigationIcon';
import { SearchBoxSmall } from './SearchBox';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import { Place } from '../pages/place';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MdArrowDropDown } from 'react-icons/md';
import { LanguageSwitcher } from '../atoms/LanguageSwitcher';
import CookiePolicyBanner from './CookiePolicyBanner';
import { useAPIBase } from '../hooks/use-api-base';
import { useLocale } from '../hooks/use-locale';
import { usePages } from '../hooks/use-pages';

const NavContainer = styled.div`
   {
    width: 100%;
    font-size: 22px;
    font-size: 14px;
    display: flex;
    background-color: #2c80e8;
    max-height: 48.5px;
  }
`;

const NavContainerMobile = styled(NavContainer)`
   {
    flex-direction: column;
    max-height: none;
  }
`;

const NavButton = styled.div<{ $active?: boolean }>`
   {
    background-color: #2c80e8;
    background-color: #2c80e8;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    border: none;
    text-align: center;
    margin: 0;
    padding: 1rem;
    border-bottom: ${props =>
      props.$active ? '2px solid rgba(255, 255, 255, 1)' : 'none'};
    & > a {
      width: 100%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      color: rgba(255, 255, 255, 1);
      text-decoration: none;
      text-transform: uppercase;
    }
  }
`;

const NavButtonIcon = styled(NavButton)`
   {
    text-align: right;
    padding: 0.5rem;
    height: 2rem;
    line-height: 2rem;
  }
`;

const LogoImageMobile = styled.img.attrs(props => ({
  src:props.src,
  alt:props.alt
}))`
  {
    float: left;
    height: 2rem;
    padding-right: 1rem;
  }
`;

const SVGWrapper = styled.div`
  {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: right;
  }
`

const SiteTitleMobile = styled.div`
  {
    text-align: left;
    display: inline-block;
    float: left;
  }
`

const SearchAndLanguageSwitcherWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const NavBar: React.FC = () => {
  const base = useAPIBase();
  const { pathname } = useLocation();
  const location = useLocation();
  const language = useLocale();
  const routerKey = location.pathname + location.search;
  const [currentPath, setCurrentPath] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMobile, setIsMobile] = useState(window && window.innerWidth > 640 ? false : true);

  const open = Boolean(anchorEl);

  const handleMenu = (event:React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  };

  const { t } = useTranslation();
  const history = useHistory();

  const resources = usePages(language);

  useEffect(() => {
    const currentPath = location.pathname;
    setCurrentPath(currentPath);
  }, [location]);

  useEffect(() => {
    function handleResize() {
      let isMobileNow = window && window.innerWidth > 640 ? false : true;
      if (isMobileNow !== isMobile)  {
        // trigger re-render when size becomes smaller or greater than 640
        setIsMobile(isMobileNow);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const handleClose = () => {
    setAnchorEl(null)
    // Also close the hamburger
    if(isMobile){
      setIsOpen(!isOpen)
    }
  };

  const GA_ANALYTICS_ID = import.meta.env.VITE_APP_GOOGLE_ANALYTICS_TRACKING_ID
  const GA_STORAGE_KEY = import.meta.env.VITE_APP_GOOGLE_ANALYTICS_STORAGE_KEY

  const getLocalePath = (path: string) => `/${language}${path}`;

  useEffect(() => {
    const validLanguages = ['en', 'ga', 'gd'];
    const pathParts = pathname.split('/');
    const pathLang = pathParts[1];
    const hash = location.hash;

    // Handle legacy hash router URLs
    if (hash && hash.startsWith('#/')) {
      const hashPath = hash.substring(1); // Remove the # character
      const storedLang = localStorage.getItem('language') || 'en';
      const targetLang = validLanguages.includes(storedLang) ? storedLang : 'en';
      const newPath = `/${targetLang}${hashPath}`;
      history.replace(newPath + location.search);
      return;
    }

    if (pathname === '/' || !validLanguages.includes(pathLang)) {
      const storedLang = localStorage.getItem('language') || 'en';
      const targetLang = validLanguages.includes(storedLang) ? storedLang : 'en';

      const newPath = pathname === '/'
        ? `/${targetLang}`
        : `/${targetLang}${pathname}`;

      history.replace(newPath + location.search);
    }
  }, [pathname, location.hash]);

  return (
    <div>
      <CookiePolicyBanner
        id={GA_ANALYTICS_ID}
        storageKey={GA_STORAGE_KEY}
        location={location}
      />
      {!isMobile ? (
        <>
          <TitleBar
            showSearch={
              (currentPath !== '/' || !currentPath.includes('/search/')) &&
              !isMobile
            }
          />
          <NavContainer>
            <NavButton $active={currentPath === `/${language}`}>
              <Link to={getLocalePath('/')}>{t('Home')}</Link>
            </NavButton>
            <NavButton $active={currentPath === `/${language}/lays`}>
              <Link to={getLocalePath('/lays')}>{t('Stories and Songs')}</Link>
            </NavButton>
            <NavButton $active={currentPath === `/${language}/places`}>
              <Link to={getLocalePath('/places')}>{t('Places')}</Link>
            </NavButton>
            <NavButton $active={currentPath === `/${language}/people`}>
              <Link to={getLocalePath('/people')}>{t('People')}</Link>
            </NavButton>
            <NavButton $active={currentPath === `/${language}/archives`}>
              <Link to={getLocalePath('/archives')}>{t('Archives')}</Link>
            </NavButton>
            <NavButton
              color="inherit"
              onClick={handleMenu}
              >
                <span style={{textTransform: 'uppercase'}}>{t('Resources')} <MdArrowDropDown /></span>
            </NavButton>
            <Menu
              id="resources-dropdown"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {resources.map((resource) => (
                <MenuItem onClick={handleClose} component={Link} to={getLocalePath(`/${resource.slug}`)} key={resource.slug}>
                  {(resource.title)}
                </MenuItem>
              ))}
            </Menu>
            <NavButton $active={currentPath === `/${language}/contact`}>
              <Link to={getLocalePath('/contact')}>{t('Contact')}</Link>
            </NavButton>
          </NavContainer>
        </>
      ) : (
        <>
          <NavButtonIcon onClick={(ev: any) => setIsOpen(!isOpen)}>
            <LogoImageMobile src='/user_files/Images/logo_tranparentbackground.png' alt='Fionn Folklore Database Logo' />
            <SiteTitleMobile>{t('Fionn Folklore Database')}</SiteTitleMobile>
            <SVGWrapper>
              <NavigationIcon />
            </SVGWrapper>
          </NavButtonIcon>
          {isOpen ? (
            <NavContainerMobile>
              <SearchAndLanguageSwitcherWrapper>
                <LanguageSwitcher />
              </SearchAndLanguageSwitcherWrapper>
              <NavButton $active={currentPath === `/${language}`} onClick={handleClose}>
                <Link to={getLocalePath('/')}>{t('Home')}</Link>
              </NavButton>
              <NavButton $active={currentPath === `/${language}/lays`} onClick={handleClose}>
                <Link to={getLocalePath('/lays')}>{t('Stories and Songs')}</Link>
              </NavButton>
              <NavButton $active={currentPath === `/${language}/places`} onClick={handleClose}>
                <Link to={getLocalePath('/places')}>{t('Places')}</Link>
              </NavButton>
              <NavButton $active={currentPath === `/${language}/people`} onClick={handleClose}>
                <Link to={getLocalePath('/people')}>{t('People')}</Link>
              </NavButton>
              <NavButton $active={currentPath === `/${language}/archives`} onClick={handleClose}>
                <Link to={getLocalePath('/archives')}>{t('Archives')}</Link>
              </NavButton>
              <NavButton $active={currentPath === `/${language}/contact`} onClick={handleClose}>
                <Link to={getLocalePath('/contact')}>{t('Contact')}</Link>
              </NavButton>
              <NavButton $active={currentPath === `/${language}/search`} onClick={handleClose}>
                <Link to={getLocalePath('/search')}>{t('Search')}</Link>
              </NavButton>
              <NavButton
              color="inherit"
              onClick={handleMenu}
              >
                <span style={{textTransform: 'uppercase'}}>{t('Resources')} <MdArrowDropDown /></span>
            </NavButton>
            <Menu
              id="resources-dropdown"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {resources.map((resource) => (
                <MenuItem onClick={handleClose} component={Link} to={getLocalePath(`/${resource.slug}`)} key={resource.slug}>
                {(resource.title)}
              </MenuItem>
              ))}

            </Menu>
            <SearchBoxSmall
              onSearch={searchValue =>
                history.push(`/search/?keywords=${searchValue}`)
              }
            />
            </NavContainerMobile>
          ) : null}
        </>
      )}
      <Switch>
        <Route exact path="/" render={() => <Home />} />
        <Route exact path="/:lang" component={Home} />
        <Route exact path="/:lang/lays" component={Lays} />
        <Route exact path="/:lang/people" component={People} />
        <Route exact path="/:lang/archives" component={Archives} />
        <Route exact path="/:lang/contact" component={Contact} />
        <Route path="/:lang/places/:id" component={Place} />
        <Route path="/:lang/places" component={Places} />
        <Route path="/:lang/lays/:type" component={LayType} />
        <Route path="/:lang/item/:id" component={Item} />
        <Route path="/:lang/institution/:id" component={Institution} />
        <Route path="/:lang/people/:id" component={Person} />
        <Route path="/:lang/search/" component={SearchPage} key={routerKey} />
        <Route path="/:lang/collection/:id" component={Collection} />
        <Route path="/:lang/:slug" component={DynamicPage} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
};
