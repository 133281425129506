import React, { useEffect } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import styled, { css } from 'styled-components';
import { MapInfoPopup } from './MapInfoPopup';
import L from 'leaflet';
// @ts-ignore
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
// @ts-ignore
import iconUrl from 'leaflet/dist/images/marker-icon.png';
// @ts-ignore
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

class IconWithID extends L.Icon.Default {
  constructor(options: { testId?: string } = {}) {
    const { testId, ...rest } = options;
    super({
      iconRetinaUrl,
      iconUrl,
      shadowUrl,
      ...rest
    } as L.IconOptions);
    this._testId = testId;
  }

  private _testId?: string;

  createIcon(oldIcon: HTMLElement) {
    const icon = super.createIcon(oldIcon);
    icon.setAttribute('data-testid', this._testId || 'map-marker');
    return icon;
  }
}

const MapBounds = styled.div<{ width?: string; height?: string }>`
  height: 400px;
  width: ${props => (props.width ? props.width : '100%')};

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 20px;
  }

  .leaflet-container {
    height: ${props => (props.height ? props.height : '400px')};

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .leaflet-marker-icon  {
    z-index: 9999 !important;
  }
`;

const defaultLatLng: LatLngTuple = [55.076474, -5.4757107];

export const LeafletMap: React.FC<{
  width?: string;
  height?: string;
  data?: PlottablePlace[]|PlottablePlace[][];
  zoom?: number;
}> = ({ width, height, data = [], zoom = 5 }) => {
  useEffect(() => {
    // @ts-ignore
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.prototype.options.iconRetinaUrl = iconRetinaUrl;
    L.Icon.Default.prototype.options.iconUrl = iconUrl;
    L.Icon.Default.prototype.options.shadowUrl = shadowUrl;
  }, []);

  // Normalize data so component can take either a single list of PlottablePlace or a list of lists
  const normalizedData = Array.isArray(data)
    ? (Array.isArray(data[0]) ? data : [data])
    : [[]];
  data = normalizedData as PlottablePlace[][];

  // Find first valid coordinate to center the map
  const findFirstValidCoordinate = (): LatLngTuple => {
    for (const sublist of data) {
      for (const point of sublist) {
        if (point.latitude_float && point.longitude_float) {
          return [point.latitude_float, point.longitude_float];
        }
      }
    }
    return defaultLatLng;
  };

  return (
    <MapBounds id="mapid" width={width} height={height}>
      <MapContainer
        center={findFirstValidCoordinate()}
        zoom={zoom}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {data.map((sublist, index) => (
          // @ts-ignore
          <MarkerClusterGroup key={`cluster-${index}`}>
            {sublist
              .filter(
                (point: any) => point.latitude_float && point.longitude_float
              )
              .map((point: any) => (
                <Marker
                  key={point.id}
                  position={[point.latitude_float, point.longitude_float]}
                  icon={new IconWithID({ testId: `map-marker-${point.id}` })}
                >
                  <MapInfoPopup placeId={point.id} />
                </Marker>
              ))}
          </MarkerClusterGroup>
        ))}
      </MapContainer>
    </MapBounds>
  );
};
