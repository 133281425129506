/** DocumentCard is tested in DocumentCard.test.tsx */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import { getESPlaceNamePriority } from '../utils/getPlaceNamePriority';
import {
  DisplayCardContainerLinkResults,
  DisplayCardTitle,
  DisplayCardIcon,
} from './DisplayCard';
import { PaddingComponent } from '../atoms/PaddingComponent';
import { Grid, GridColumn } from './Grid';
import { Id, SongStoryTitleContainer } from './SongStory.style';
import { TileDetail } from './TileDetail';
import { ESDocument, PersonType } from '../types/ElasticResponse';
import { MicrophoneIcon, OpenBookIcon } from '../icons/CardIcons';

interface DocumentCardProps {
  result: ESDocument;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({ result }) => {
  const locale = useLocale() || 'en';
  const { t } = useTranslation();

  function listOfPeoplesNames(peopleInRoles: PersonType[]): string {
    return peopleInRoles
      .map(person => {
        if (!person.canonical_name && !person.name) return t("U");
        return (
          person.canonical_name ||
          Object.values(person.name)
            .filter(name => name.name && name.name.includes(locale))
            .join(', ')
        );
      })
      .join(', ');
  }

  return (
    <DisplayCardContainerLinkResults
      to={`/item/${result.new_id}`}
      target={"_blank"}
      rel={"noopener noreferrer"}
      backgroundcolor='#eef5ff'
      className="es-result-card document"
    >
      <SongStoryTitleContainer>
        <DisplayCardTitle>{result.title ?? t('untitled')}</DisplayCardTitle>
        <Id>ID: {result.new_id}</Id>
        <DisplayCardIcon>
          {t('Document')}
          {result.document_witness && result.document_witness[0] && result.document_witness[0].type === 'r' ? (
            <MicrophoneIcon data-testid="microphone-icon" />
          ) : (
            <OpenBookIcon data-testid="book-icon" />
          )}
        </DisplayCardIcon>
      </SongStoryTitleContainer>
      <PaddingComponent/>
      <Grid>
        <GridColumn>
          {result.collection_event && result.collection_event.eventrole && result.collection_event.eventrole.map((eventRole, index) => (
            <TileDetail key={index} label={t(eventRole.role.split(' ')[0])}>
              {listOfPeoplesNames([eventRole.person])}
            </TileDetail>
          ))}
          {result.collection_event.place_of_collection && (
            <TileDetail label={t('Location')}>
              {[
                ...getESPlaceNamePriority(result.collection_event.place_of_collection, locale),
              ].join(', ')}
            </TileDetail>
          )}
          {(result.collection_event && (
            result.collection_event.collection_date ||
            result.collection_event.collection_range_start_date ||
            result.collection_event.collection_range_end_date
          )) && (
            <TileDetail label={t('Date')}>
              { result.collection_event.collection_date ||
              `${result.collection_event.collection_range_start_date} - ${result.collection_event.collection_range_end_date}`}
            </TileDetail>
          )}
          {result.institution && result.institution.institution_name && (
            <TileDetail label={t('Archive')}>{result.institution.institution_name}</TileDetail>
          )}
          {result.collection && result.collection.collection_name && (
            <TileDetail label={t('Collection')}>{result.collection.collection_name}</TileDetail>
          )}
        </GridColumn>
      </Grid>
    </DisplayCardContainerLinkResults>
  );
}
