import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAPIBase } from '../hooks/use-api-base';
import { useFetch } from '../hooks/use-fetch-data';
import { useLocale } from '../hooks/use-locale';
import { useTranslation } from 'react-i18next';
import { GridColumn } from '../components/Grid';
import { PageContainer } from '../atoms/PageContainer';
import { SubTitleText } from '../atoms/TitleText';
import { BreadCrumbs } from '../atoms/BreadCrumbs';
import { Link } from 'react-router-dom';
import { Loading } from '../atoms/loading';
import { SongStory } from '../components/SongStory';
import { getAlternativeTitles } from '../utils/getAlternativeTitles';
import {
  Title, TitleSpacer, KeyValuePair, Label30, Value70, QuillFw
} from '../atoms/StyledShared';
import { getLocaleKeyVal } from '../utils/getLocaleKeyVal';

export const LayType: React.FC = () => {
  const { pathname } = useLocation();
  const base = useAPIBase();
  const [data, setData] = useState<any>();
  const language = useLocale();
  const { t } = useTranslation();
  const plot_summary = getLocaleKeyVal(data, 'plot_summary', language);

  useEffect(() => {
    (async () => {
      const data = await useFetch(
        `${base}/laytype/${pathname.split('/').pop()}/`
      );
      if (data) setData(data);
    })();
  }, [base, pathname]);
  const locale = useLocale();
  const getLocaleLang = (obj: any, lang: string | null) => {
    const options = Object.keys(obj);
    let key = '';
    switch (lang) {
      case 'ga':
        key = options.find(key => key.includes('_ga')) || '';
        break;
      case 'gd':
        key = options.find(key => key.includes('_ga')) || '';
        break;
      default:
        key = 'title_en';
    }
    return key ? obj[key] : obj.title_en;
  };
  return data ? (
    <PageContainer>
      <BreadCrumbs>
        <Link to="/">{t('Home')}</Link>
        {'>'}
        <Link to="/lays">{t('Stories and Songs')}</Link>
        {'>'}
        <Link to={pathname}>{getLocaleLang(data, language as string)}</Link>
      </BreadCrumbs>
      <TitleSpacer>
        <Title>
          {getLocaleLang(data, 'ga')}
          {language === 'en' ? ' (' + getLocaleLang(data, 'en') + ') ' : null}
        </Title>
      </TitleSpacer>
      {
        data && data.alternative_titles && data.alternative_titles.length > 0 &&
        <KeyValuePair>
          <Label30>
            {t('Also known as')}
          </Label30>
          <Value70>
            {getAlternativeTitles(data.alternative_titles, locale)}
          </Value70>
        </KeyValuePair>
      }
      {
        data && data.further_information &&
        <KeyValuePair>
          <Label30>
            {t('Further Information')}
          </Label30>
          <Value70>
            {data.further_information}
          </Value70>
        </KeyValuePair>
      }
      {
        data && data.international_tale_type &&
        <KeyValuePair>
          <Label30>
            {t('International Tale Type')}
          </Label30>
          <Value70>
            {data.international_tale_type}
          </Value70>
        </KeyValuePair>
      }
      {
        plot_summary &&
        <QuillFw dangerouslySetInnerHTML={{__html: plot_summary}} />
      }
      <SubTitleText>
        {`${t('Titles associated with this story/song')} (${
          data && data.instances_of_this_type
            ? data.instances_of_this_type.length
            : 0})`
        }
      </SubTitleText>
      <GridColumn>
        {data?.instances_of_this_type &&
          data.instances_of_this_type.map((story: any) => (
            story.document ? (
              <SongStory key={story.document.new_id} {...story.document} />
            ) : null
          ))}
      </GridColumn>
    </PageContainer>
  ) : (
    <Loading>
      <div className="loader"></div>
    </Loading>
  );
};
