import { useState, useEffect } from 'react';
import { useAPIBase } from './use-api-base';

type Language = 'en' | 'ga' | 'gd';

export const usePages = (langCode: string) => {
  const base = useAPIBase();
  const [resources, setResources] = useState([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const validLanguages: Language[] = ['en', 'ga', 'gd'];

    if (!langCode || !validLanguages.includes(langCode as Language)) {
      setError('Invalid language code');
      return;
    }

    const fetchPageListData = async () => {
      const localeQueryParam = `?locale=${langCode}`;
      const langslug = langCode !== 'en' ? `-${langCode}` : '';
      const listUrl = `${base}/pages/resources${langslug}/${localeQueryParam}`;

      try {
        const response = await fetch(listUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setResources(data?.child_pages || []);
        setError(null);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError('Failed to fetch resources');
        setResources([]);
      }
    };

    fetchPageListData();
  }, [base, langCode]);

  return resources;
};
